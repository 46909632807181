<template>
    <article class="page">
        <article-header :section-type-class="'section--lg'">
            <template v-slot:firstCol>
                <h1>
                    BarnehageHMS - et verktøy for bedre jobbhelse
                </h1>
                <p class="lead mb-40">
                    BarnehageHMS er et verktøy for barnehager som ønsker å jobbe
                    med arbeidsmiljø, HMS og helsefremming, systematisk og
                    jevnlig. BarnehageHMS har en kalender som skal være en hjelp
                    til å sette på dagsorden arbeidsmiljøtema som er viktig for
                    dere, i den barnehagehverdagen akkurat dere har.
                </p>
                <p class="lead mb-40">
                    Barnehagene får tilgang til 13 tema med korte tekster som
                    bygger på forskning, gir tips og råd, har diskusjonspunkt og
                    utvalgte lenker til hjelp i arbeidet. Se lenken "HMS-tema" i
                    toppmenyen.
                </p>

                <router-link
                    v-if="!isLoggedIn()"
                    :to="{
                        name: routes.registration.organisasjon
                    }"
                    tag="button"
                    class="btn btn--primary btn--lg"
                >
                    Registrer barnehage
                </router-link>

                <router-link
                    v-if="isLoggedIn() && !isSysAdmin()"
                    :to="{
                        name: routes.kalender
                    }"
                    tag="button"
                    class="btn btn--primary btn--lg"
                >
                    Til kalender
                </router-link>
            </template>
            <template v-slot:secondCol>
                <img
                    src="../assets/images/illustration_banner_start.svg"
                    alt=""
                    class="w-100"
                />
            </template>
        </article-header>

        <section class="section section--lg flex-grow">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 d-flex justify-content-center">
                        <img
                            src="../assets/images/illustration_block_start.svg"
                            alt=""
                        />
                    </div>
                    <div class="col-md-6 d-flex align-items-center">
                        <div>
                            <h2 class="mb-20">
                                Arbeidsmiljø handler om arbeidet
                            </h2>
                            <p class="no-pre">
                                Arbeidsmiljø handler om arbeidet, og påvirker
                                både helse, engasjement, nærvær og kvaliteten i
                                barnehagene. Noen arbeidsmiljøfaktorer går igjen
                                i alle barnehager, men mye er også forskjellig.
                                Kalenderen gir dere selv mulighet til å velge ut
                                hva som er viktigst for dere. Barnehagene får
                                tilgang til kunnskap om de mest relevante
                                faktorene som påvirker arbeidsmiljø,
                                arbeidshelse, engasjement og kvalitet i
                                arbeidshverdagen.
                            </p>
                            <p class="no-pre">
                                Det er viktig at hele personalet er involvert,
                                men det er leder, tillitsvalgt og verneombud
                                (HMS-gruppa) som har et hovedansvar i
                                BarnehageHMS.
                            </p>
                            <h3 class="mb-5">
                                IA-bransjeprogram barnehage står bak dette
                                verktøyet:
                            </h3>
                            <p class="no-pre">
                                LO, Unio, KS, Virke, Fagforbundet,
                                Utdanningsforbundet, YS, Delta, Norlandia, FUS,
                                PBL, KA, NAV og Arbeidstilsynet
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </article>
</template>

<script>
    import ArticleHeader from '../components/Framework/ArticleHeader';

    export default {
        components: {
            'article-header': ArticleHeader
        }
    };
</script>
