<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
                <div v-if="token">
                    <LoginForm
                        :title="'Logg inn for å verifisere epostadressen din'"
                        :btn-title="'Logg inn og gå videre'"
                        :username="username"
                        :token="token"
                        :redirect="routes.registration.users"
                    />
                </div>
                <form v-else action="/" @submit.prevent="onSubmit">
                    <div class="section section--form">
                        <fieldset>
                            <legend>
                                <h2>Barnehage opprettet</h2>
                                <p class="sm">
                                    En verifiseringskode har blitt sendt til din
                                    epost, skriv den inn i feltet under eller
                                    følg lenken i eposten for å verifisere
                                    epostadressen din.
                                </p>
                            </legend>
                            <FormGroup
                                v-bind="fields.token"
                                :value.sync="fields.token.value"
                                @on-blur="onFieldBlur"
                                @on-change="onChange"
                            />
                        </fieldset>
                    </div>
                    <button
                        class="btn btn--primary btn--lg btn--block"
                        @click.prevent="onNextClick"
                    >
                        Neste
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import validationMixin from '../../../../mixins/validationMixin';
    import validate from '../../../../scripts/validate';

    import LoginForm from '../../../../components/shared/LoginForm';
    import regProps from '../../../../constants/regProps';

    export default {
        components: {
            LoginForm
        },
        mixins: [validationMixin],
        data() {
            return {
                token: null,
                username: null,
                fields: {
                    token: {
                        type: 'text',
                        name: 'token',
                        value: '',
                        label: 'Verifiseringskode *',
                        error: '',
                        autocomplete: 'off',
                        id: 'token'
                    }
                }
            };
        },
        computed: {
            ...mapState(['regProps'])
        },
        methods: {
            onNextClick() {
                if (this.isFormValid()) {
                    if (!this.username) {
                        this.username = this.regProps[regProps.email];
                    }
                    this.token = this.fields.token.value;
                }
            },

            validateField(field) {
                let error = null;

                if (field.name === this.fields.token.name) {
                    if (!validate.stringLength(field.value)) {
                        error = 'Verifiseringskode må fylles inn';
                    }
                }

                field.error = error || '';
                field.validated = error ? true : false;

                return error;
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                if (to.query) {
                    vm.token = to.query.token;
                    vm.username = to.query.username;
                }
            });
        }
    };
</script>
