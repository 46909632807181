export default {
    error: 'Feil',
    start: 'Startsiden',
    login: 'Logg inn',
    logout: 'Logg ut',
    forgotPassword: 'Glemt passord',
    resetPassword: 'Resett passord',
    introduction: 'Om BarnehageHMS',
    kalender: 'Kalender',
    kalenderMonth: 'Kalender måned',
    kalenderHalvaar: 'Kalender Halvår',
    createAktivitet: 'Ny aktivitet',
    editAktivitet: 'Rediger aktivitet',
    temaer: 'Temaer',
    tema: 'Tema',
    statistikk: 'Statistikk',
    editTemaer: 'Rediger Temaer',
    editTema: 'Rediger Tema',
    createTema: 'Nytt tema',
    registration: {
        organisasjon: 'organisasjon',
        barnehage: 'Registrering: Barnehageinfo',
        adminBruker: 'Registrering: Adminbruker',
        confirm: 'Registrering: Bekreft',
        users: 'Registrering: Registrer ansatte',
        verifyEmail: 'Registrering: Verifiser epost',
        success: 'Registrering fullført',
        user: 'Registrer bruker'
    },
    admin: {
        users: 'Brukeradministrering',
        addUsers: 'Registrere nye ansatte',
        user: 'Redigere ansatt'
    }
};
