import { mapState } from 'vuex';
import {
    isLoginChecked,
    isLoggedIn,
    isUser,
    isOrgAdmin,
    isSysAdmin
} from '../scripts/auth';

export default {
    computed: {
        ...mapState(['loggedInStatus'])
    },
    methods: {
        isLoginChecked: isLoginChecked,
        isLoggedIn,
        isUser,
        isOrgAdmin,
        isSysAdmin
    }
};
