<template>
    <div>
        <form action="/" @submit.prevent="onSubmit">
            <fieldset>
                <legend>
                    <h2>{{ title }}</h2>
                </legend>

                <FormGroup
                    v-bind="fields.username"
                    :value.sync="fields.username.value"
                    :maxlength="fields.username.maxlength"
                    @on-blur="onFieldBlur"
                    @on-change="onChange"
                    :autofocus="true"
                />

                <FormGroup
                    v-bind="fields.password"
                    :value.sync="fields.password.value"
                    :maxlength="fields.password.maxlength"
                    @on-blur="onFieldBlur"
                    @on-change="onChange"
                />

                <FormGroup
                    v-if="showTokenField"
                    v-bind="fields.token"
                    :value.sync="fields.token.value"
                    @on-blur="onFieldBlur"
                    @on-change="onChange"
                />
            </fieldset>

            <p class="form-error" v-if="loginError" aria-live="assertive">
                {{ loginError }}
            </p>

            <button
                type="submit"
                class="btn btn--primary btn--lg btn--block btn--form mb-30"
            >
                {{ btnTitle }}
            </button>

            <div class="text-center mb-40">
                <router-link
                    :to="{
                        name: routes.forgotPassword,
                        params: {
                            username: fields.username.value
                        }
                    }"
                >
                    Glemt passord?
                </router-link>
            </div>
        </form>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import validationMixin from '../../mixins/validationMixin';
    import routeHandler from '../../router/routeHandler';
    import { login } from '../../scripts/auth';
    import validate from '../../scripts/validate';

    export default {
        mixins: [validationMixin],
        props: {
            title: {
                type: String,
                default: 'Logg inn'
            },
            btnTitle: {
                type: String,
                default: 'Logg inn'
            },
            token: String,
            username: String,
            redirect: String
        },
        data() {
            return {
                fields: {
                    username: {
                        type: 'email',
                        name: 'username',
                        value: '',
                        hideLabel: true,
                        label: 'E-post *',
                        placeholder: 'E-post',
                        maxlength: 254,
                        id: 'username',
                        autocomplete: 'username',
                        error: ''
                    },
                    password: {
                        type: 'password',
                        name: 'password',
                        value: '',
                        hideLabel: true,
                        label: 'Passord *',
                        placeholder: 'Passord',
                        maxlength: 255,
                        id: 'current-password',
                        autocomplete: 'current-password',
                        error: ''
                    },
                    token: {
                        type: 'text',
                        name: 'token',
                        value: '',
                        label: 'Verifiseringskode *',
                        error: '',
                        autocomplete: 'off',
                        id: 'token'
                    }
                },
                loginError: null,
                showTokenField: false
            };
        },
        computed: {
            ...mapState(['loggedInStatus'])
        },
        watch: {
            token: {
                handler: function(token) {
                    this.fields.token.value = token;
                },
                immediate: true
            }
        },
        methods: {
            onSubmit() {
                if (this.isFormValid()) {
                    let token = this.fields.token.value
                        ? this.fields.token.value.trim()
                        : null;
                    login(
                        this.fields.username.value,
                        this.fields.password.value,
                        token
                    )
                        .then(() => {
                            if (!this.redirect) {
                                routeHandler.loggedInRedirectHandler(
                                    this.$route.query.redirect
                                );
                            } else {
                                this.$router.push({
                                    name: this.redirect
                                });
                            }
                            this.fields.password.value = '';
                        })
                        .catch(err => {
                            const errors = err.response.data.errors;
                            if (errors && errors.hasOwnProperty('token')) {
                                this.fields.token.error = errors.token.join(
                                    ', '
                                );
                                this.showTokenField = true;
                            } else {
                                this.fields.password.value = '';
                                this.loginError =
                                    'Kunne ikke logge deg inn. Kontroller at epost og passord er korrekt';
                            }
                        });
                }
            },
            validateField(field) {
                let error = null;

                if (field.name === this.fields.username.name) {
                    if (!validate.emailIsValid(field.value)) {
                        error = 'Ugyldig e-post';
                    }
                    if (!validate.stringLength(field.value)) {
                        error = 'E-post må fylles inn';
                    }
                }

                if (field.name === this.fields.password.name) {
                    if (!validate.stringLength(field.value, 1)) {
                        error = 'Passord må fylles inn';
                    }
                }

                field.error = error || '';
                field.validated = error ? true : false;

                return error;
            }
        },
        beforeMount() {
            if (this.$route.query.user) {
                this.fields.username.value = this.$route.query.user;
            }

            if (this.username) {
                this.fields.username.value = this.username;
                this.fields.username.disabled = true;
            }
        }
    };
</script>
