<template>
    <div
        class="tema-list-item"
        :class="hover ? 'hover' : null"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
        @click.prevent="onClick"
    >
        <div class="tema-list-item__image-container">
            <ImageFile :id="bilde" :alt="'Illustrasjon ' + tittel" />
        </div>
        <div>
            <TemaLink href="#" :id="id" :tittel="tittel" />
        </div>
    </div>
</template>

<script>
    import TemaLink from '../shared/TemaLink';
    import ImageFile from '../shared/ImageFile';

    export default {
        components: {
            TemaLink,
            ImageFile
        },
        props: {
            id: String,
            tittel: String,
            bilde: String,
            ingress: String
        },
        data() {
            return {
                hover: false
            };
        },
        methods: {
            onClick(e) {
                if (e.ctrlKey || e.metaKey) {
                    let routeData = this.$router.resolve({
                        name: this.routes.tema,
                        params: { id: this.id, title: this.tittel }
                    });
                    window.open(routeData.href, '_blank');
                } else {
                    this.$router.push({
                        name: this.routes.tema,
                        params: {
                            id: this.id,
                            title: this.tittel
                        }
                    });
                }
            }
        }
    };
</script>
