var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{staticClass:"page"},[_c('ArticleHeader',{scopedSlots:_vm._u([{key:"firstCol",fn:function(){return [_c('h1',[_vm._v(_vm._s(_vm.tema.tittel))]),(_vm.tema.ingress)?_c('p',{staticClass:"lead pre"},[_vm._v(_vm._s(_vm.tema.ingress))]):_vm._e()]},proxy:true},(_vm.tema.bilde)?{key:"secondCol",fn:function(){return [_c('ImageFile',{attrs:{"id":_vm.tema.bilde,"alt":'Illustrasjon ' + _vm.tema.tittel}})]},proxy:true}:null],null,true)}),_c('section',{staticClass:"section section--lg flex-grow"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[(
                        _vm.tema.innholdsblokker &&
                            !!_vm.tema.innholdsblokker.length
                    )?_c('div',{staticClass:"col-lg-6 offset-lg-3"},_vm._l((_vm.tema.innholdsblokker),function(innholdsblokk){return _c('div',{key:innholdsblokk.tittel},[_c('h2',[_vm._v(_vm._s(innholdsblokk.tittel))]),_c('p',[_vm._v(_vm._s(innholdsblokk.brodtekst))])])}),0):_vm._e(),(
                        _vm.tema.diskusjonspunkter &&
                            !!_vm.tema.diskusjonspunkter.length
                    )?_c('div',{staticClass:"col-lg-6 offset-lg-3"},[_c('div',{staticClass:"bg section section--bullets"},[_c('h2',[_vm._v("Diskusjonspunkter")]),_c('ul',{staticClass:"mb-0 list--square"},_vm._l((_vm.tema.diskusjonspunkter),function(diskusjonspunkt,i){return _c('li',{key:diskusjonspunkt.text,class:i < _vm.tema.diskusjonspunkter.length - 1
                                        ? 'mb-10'
                                        : null},[_vm._v(" "+_vm._s(diskusjonspunkt.tekst)+" ")])}),0)])]):_vm._e(),(_vm.tema.lenker && !!_vm.tema.lenker.length)?_c('div',{staticClass:"col-lg-6 offset-lg-3"},[_c('div',{staticClass:"section--link-list"},[_c('h2',[_vm._v("Verktøy og lenker")]),_c('ul',{staticClass:"list--unstyled"},_vm._l((_vm.tema.lenker),function(link){return _c('li',{key:link.lenketekst,staticClass:"mb-20"},[_c('a',{staticClass:"link--arrow-right",attrs:{"href":link.url}},[_c('span',{staticClass:"icon icon--arrow-right"}),_c('span',{staticClass:"link__text",domProps:{"textContent":_vm._s(link.lenketekst)}})])])}),0)])]):_vm._e()])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"d-none d-lg-block"},[_c('a',{attrs:{"href":"javascript:window.print();"}},[_vm._v("Skriv ut")])])])}]

export { render, staticRenderFns }