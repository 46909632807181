<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
                <div class="section section--form">
                    <form
                        id="create-user"
                        action="/"
                        @submit.prevent="onSubmit"
                    >
                        <fieldset>
                            <legend>
                                <h2>Opprett adminbruker</h2>
                                <p class="sm">
                                    Registrer personinfo og opprett bruker
                                    dersom du er leder i en barnehage.
                                </p>
                            </legend>
                            <FormGroup
                                v-bind="fields.name"
                                :value.sync="fields.name.value"
                                @on-blur="onFieldBlur"
                                @on-change="onChange"
                            />
                            <FormGroup
                                v-bind="fields.email"
                                :value.sync="fields.email.value"
                                @on-blur="onFieldBlur"
                                @on-change="onChange"
                            />
                            <FormGroup
                                v-bind="fields.password"
                                :value.sync="fields.password.value"
                                @on-blur="onFieldBlur"
                                @on-change="onChange"
                            />
                            <FormGroup
                                v-bind="fields.re_password"
                                :value.sync="fields.re_password.value"
                                @on-blur="onFieldBlur"
                                @on-change="onChange"
                            />
                        </fieldset>
                    </form>
                </div>
                <div class="d-flex align-items-center">
                    <div class="flex-grow">
                        <router-link
                            :to="{ name: routes.registration.barnehage }"
                        >
                            Tilbake
                        </router-link>
                    </div>
                    <div>
                        <button
                            type="submit"
                            form="create-user"
                            class="btn btn--primary btn--lg"
                            @click.prevent="onNextClick"
                        >
                            Neste
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex';
    import validationMixin from '../../../../mixins/validationMixin';
    import {
        inputsToObject,
        mapErrorsToInputs
    } from '../../../../scripts/utils';
    import validate from '../../../../scripts/validate';
    import regProps from '../../../../constants/regProps';

    export default {
        mixins: [validationMixin],
        data() {
            return {
                fields: {
                    name: {
                        type: 'text',
                        name: regProps.name,
                        value: '',
                        label: 'Navn *',
                        error: '',
                        autocomplete: 'name',
                        maxlength: 200
                    },
                    email: {
                        type: 'email',
                        name: regProps.email,
                        value: '',
                        label: 'E-post *',
                        placeholder: 'E-post',
                        autocomplete: 'email',
                        maxlength: 254,
                        id: 'email',
                        error: ''
                    },
                    password: {
                        type: 'password',
                        name: regProps.password,
                        value: '',
                        label: 'Nytt passord *',
                        error: '',
                        maxlength: 255,
                        id: 'new-password',
                        autocomplete: 'new-password'
                    },
                    re_password: {
                        type: 'password',
                        name: 're_password',
                        value: '',
                        label: 'Bekreft passord *',
                        error: '',
                        autocomplete: 'new-password'
                    }
                }
            };
        },
        computed: {
            ...mapState(['regProps'])
        },
        methods: {
            ...mapActions(['setRegProps']),
            onNextClick() {
                if (this.isFormValid()) {
                    let regProps = inputsToObject(this.fields, [
                        this.fields.re_password.name
                    ]);

                    this.setRegProps(regProps).then(() => {
                        this.$router.push({
                            name: this.routes.registration.confirm
                        });
                    });
                }
            },
            validateField(field) {
                let error = null;

                switch (field.name) {
                    case this.fields.name.name:
                        if (!validate.stringLength(field.value)) {
                            error = 'Navn må fylles inn';
                        }
                        break;
                    case this.fields.email.name:
                        if (!validate.emailIsValid(field.value)) {
                            error = 'Ugyldig e-post';
                        }
                        if (!validate.stringLength(field.value)) {
                            error = 'E-post må fylles inn';
                        }
                        break;
                    case this.fields.password.name:
                    case this.fields.re_password.name:
                        error = validate.validatePasswords(
                            field,
                            this.fields.password,
                            this.fields.re_password
                        );
                        break;
                }

                field.error = error ? error : '';
                field.validated = error !== null;

                return error;
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                if (to.params.errors) {
                    vm.fields.password.value = '';
                    vm.fields.re_password.value = '';
                    mapErrorsToInputs(vm.fields, to.params.errors);
                }
                if (
                    !vm.regProps[regProps.barnehagenavn] ||
                    !vm.regProps[regProps.organisasjonsnummer]
                ) {
                    return next({ name: vm.routes.registration.barnehage });
                }
            });
        }
    };
</script>
