<template>
    <div>
        <textarea
            v-if="type === 'textarea'"
            class="form-control"
            :class="inputClassName"
            :id="id"
            :name="name"
            :rows="rows"
            :value="value"
            :placeholder="placeholder"
            :aria-describedby="ariaDescribedby"
            :maxlength="maxlength"
            @input="onChange($event)"
            @blur="onBlur($event)"
        ></textarea>
        <input
            v-else
            class="form-control"
            :class="inputClassName"
            :id="id"
            :name="name"
            :type="type"
            :value="value"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :autofocus="autofocus ? 'autofocus' : null"
            :aria-describedby="ariaDescribedby"
            :maxlength="maxlength"
            @input="onChange($event)"
            @blur="onBlur($event)"
            :disabled="disabled ? 'disabled' : null"
        />
    </div>
</template>

<script>
    export default {
        props: {
            value: String,
            id: String,
            type: {
                type: String,
                default: 'text'
            },
            name: String,
            rows: {
                type: Number,
                default: 7
            },
            placeholder: String,
            autocomplete: {
                type: String,
                default: 'off'
            },
            ariaDescribedby: {
                type: String,
                default: null
            },
            hasError: {
                type: Boolean,
                default: false
            },
            autofocus: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            inputClass: String,
            maxlength: {
                type: Number
            }
        },
        computed: {
            inputClassName() {
                var classes = [];
                if (this.inputClass) {
                    classes.push(this.inputClass);
                }

                if (this.hasError) {
                    classes.push('error');
                }

                return classes;
            }
        },
        methods: {
            onChange(event) {
                if (event.which == 13 || event.keyCode == 13) {
                    return;
                }
                this.$emit('update:value', event.target.value);
                this.$emit('on-change', event);
            },
            onBlur(event) {
                this.$emit('on-blur', event);
            }
        }
    };
</script>
