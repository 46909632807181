import store from '../store';
import api from './api';

export const userRoles = {
    user: 'OB',
    orgAdmin: 'OA',
    sysAdmin: 'SA'
};

export const login = (username, password, token = null) => {
    return new Promise((resolve, reject) => {
        api.tryLogin(username, password, token)
            .then(res => {
                const loggedInStatus = res.loginStatus;

                store
                    .dispatch('setLoggedInStatus', loggedInStatus)
                    .then(() => resolve(res))
                    .catch(() => {});
            })
            .catch(err => {
                reject(err);
            });
    });
};

export const logout = () => {
    return new Promise((resolve, reject) => {
        api.logout().then(() => {
            store.dispatch('updateCacheKey').then(() => {
                store
                    .dispatch('setLoggedInStatus', { isLoggedIn: false })
                    .then(res => resolve(res))
                    .catch(err => reject(err));
            });
        });
    });
};

export const refreshLoginStatus = () => {
    return new Promise((resolve, reject) => {
        api.loginStatus()
            .then(res => {
                const loggedInStatus = res;
                store
                    .dispatch('setLoggedInStatus', loggedInStatus)
                    .then(() => resolve());
            })
            .catch(() => {
                store
                    .dispatch('setLoggedInStatus', { isLoggedIn: false })
                    .then(() => reject());
            });
    });
};

export const isLoginChecked = () => {
    return store.state.loggedInStatus !== null;
};

export const isLoggedIn = () => {
    return isLoginChecked() && store.state.loggedInStatus.isLoggedIn;
};

export const isSysAdmin = () => {
    return (
        isLoggedIn() &&
        store.state.loggedInStatus.rolletype === userRoles.sysAdmin
    );
};

export const isOrgAdmin = () => {
    return (
        isSysAdmin() ||
        (isLoggedIn() &&
            store.state.loggedInStatus.rolletype === userRoles.orgAdmin)
    );
};

export const isUser = () => {
    return (
        isOrgAdmin() ||
        (isLoggedIn() &&
            store.state.loggedInStatus.rolletype === userRoles.user)
    );
};

export const auth = {
    userRoles,
    login,
    isLoginChecked,
    refreshLoginStatus,
    isUser,
    isOrgAdmin,
    isSysAdmin,
    isLoggedIn
};
