<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
                <div class="section section--form">
                    <h2>Registrering fullført</h2>
                    <p class="mb-30">
                        Du har nå fullført registreringen i BarnehageHMS.
                    </p>
                </div>
                <button class="btn btn--primary btn--lg btn--block"
                        @click.prevent="onConfirmClick">
                    Introduksjon til verktøyet
                </button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        methods: {
            onConfirmClick() {
                window.dataLayer?.push({
                    event: 'form_submitted',
                    form_name: 'registrering-fullfoert'
                });
                this.$router.push({
                    name: this.routes.introduction
                });
            }
        }
    }
</script>