<template>
    <div>
        <div class="section section--form">
            <fieldset>
                <legend>
                    <h2>Registrer ansatte</h2>
                    <p class="sm">
                        Registrer e-posten til dine ansatte for å gi de tilgang
                        til verktøyet.
                    </p>
                </legend>
                <ul class="list--unstyled list--edit" v-if="fields.length">
                    <li
                        v-for="(field, i) in fields"
                        :key="'user_' + i"
                        class="input-group"
                    >
                        <FormGroup
                            v-bind="field"
                            :value.sync="field.value"
                            @on-blur="onFieldBlur"
                            @on-change="onChange"
                            :input-class="'mb-10'"
                        />
                        <label :for="'admin_' + i" class="checkbox-group">
                            <input
                                type="checkbox"
                                :id="'admin_' + i"
                                v-model="field.orgAdmin"
                            />
                            <span class="sm">&nbsp;Skrivetilgang</span>
                        </label>
                    </li>
                </ul>

                <button
                    class="btn btn--primary btn--add"
                    @click.prevent="onAddUserClick"
                >
                    <span class="icon icon--add--white"></span>
                    Legg til e-post felt
                </button>
            </fieldset>
        </div>

        <button
            class="btn btn--primary btn--block btn--lg mb-30"
            @click.prevent="onRegisterUsersClick"
        >
            Send e-post til ansatte
        </button>
    </div>
</template>

<script>
    import validate from '../../scripts/validate';
    import ValidationMixin from '../../mixins/validationMixin';
    import api from '../../scripts/api';
    import { mapErrorsToInputs } from '../../scripts/utils';

    export default {
        mixins: [ValidationMixin],
        data() {
            return {
                userIndex: 0,
                fields: [],
                newUser: {
                    type: 'email',
                    value: '',
                    orgAdmin: false,
                    name: '',
                    maxlength: 254,
                    label: 'E-post',
                    hideLabel: true,
                    placeholder: 'E-post',
                    error: '',
                    validated: false
                }
            };
        },
        methods: {
            onAddUserClick() {
                this.addUser();
            },
            addUser() {
                let newUser = Object.assign({}, this.newUser);
                newUser.name = `email_${this.userIndex}`;
                this.userIndex++;
                this.fields.push(newUser);
            },
            onRegisterUsersClick() {
                const self = this;

                if (this.isFormValid()) {
                    window.dataLayer?.push({
                        event: 'form_submitted',
                        form_name: 'legg-til-brukere'
                    });
                    let users = this.fields
                        .filter(user => user.value.length > 0)
                        .map(user => {
                            return {
                                id: user.name,
                                epost: user.value,
                                skrivetilgang: user.orgAdmin
                            };
                        });

                    api.registerNewUsers(users)
                        .then(() => {
                            this.$router
                                .push({
                                    name: this.routes.registration.success
                                })
                                .catch(() => {});
                        })
                        .catch(err => {
                            const errors = err.response.data.errors;

                            if (errors) {
                                let mapErrors = {};
                                for (let key in errors) {
                                    mapErrors[key.split(':')[1]] = errors[key];
                                }
                                mapErrorsToInputs(self.fields, mapErrors);
                            }
                        });
                }
            },
            getFieldByName(name) {
                return this.fields.find(field => field.name === name);
            },
            validateField(field) {
                if (!field.value.length) {
                    field.error = '';
                    field.validated = false;
                    return;
                }

                if (!validate.emailIsValid(field.value)) {
                    field.error = 'Ugyldig e-post';
                } else {
                    field.error = '';
                }

                field.validated = true;
            }
        },
        beforeMount() {
            for (let index = 0; index < 4; index++) {
                this.addUser();
            }
        }
    };
</script>
