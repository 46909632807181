<template>
    <div class="page">
        <div class="section flex-grow bg--light">
            <div class="container">
                <h1 class="mb-70">HMS-tema</h1>
                <ul v-if="temaer.length" class="row list--unstyled">
                    <li class="col-md-6" v-for="tema in temaer" :key="tema.id">
                        <TemaListItem
                            :id="tema.id"
                            :tittel="tema.tittel"
                            :bilde="tema.bilde"
                        />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '../../scripts/api';
    import TemaListItem from '../../components/List/TemaListItem';

    export default {
        components: {
            TemaListItem
        },
        data() {
            return {
                temaer: []
            };
        },
        beforeMount() {
            api.getTemaer().then(res => {
                this.temaer = res;
            });
        }
    };
</script>
