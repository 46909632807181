<template>
    <router-link
        :to="{
            name: routes.tema,
            params: {
                id: id
            }
        }"
    >
        {{ tittel }}
    </router-link>
</template>

<script>
    export default {
        props: {
            id: String,
            tittel: String
        }
    };
</script>
