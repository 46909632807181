<template>
    <div class="page">
        <div class="section bg--light">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                        <ul class="reg-steps">
                            <li
                                v-for="(step, index) in steps"
                                :key="index"
                                class="reg-step"
                                :class="getStepClass(index)"
                            >
                                <div class="reg-step__number">
                                    {{ step.number }}
                                </div>
                                <div class="reg-step__title">
                                    {{ step.title }}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="section bg--light flex-grow">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                steps: [],
                currentStepIndex: 0
            };
        },
        watch: {
            '$route.name': {
                handler: function(routeName) {
                    this.setCurrentStep(routeName);
                },
                immediate: true
            }
        },
        methods: {
            setCurrentStep(routeName) {
                this.steps.forEach((step, index) => {
                    if (step.routeName === routeName) {
                        this.currentStepIndex = index;
                    }
                });
            },
            getStepClass(index) {
                if (this.$route.name === this.routes.registration.success) {
                    return 'reg-step--complete';
                }

                if (this.currentStepIndex === index) {
                    return 'reg-step--current';
                }

                if (this.currentStepIndex > index) {
                    return 'reg-step--complete';
                }

                return '';
            }
        },
        beforeMount() {
            this.steps = [
                {
                    number: 1,
                    title: 'Registrer barnehage',
                    routeName: this.routes.registration.barnehage
                },
                {
                    number: 2,
                    title: 'Opprett adminbruker',
                    routeName: this.routes.registration.adminBruker
                },
                {
                    number: 3,
                    title: 'Bekreft informasjon',
                    routeName: this.routes.registration.confirm
                },
                {
                    number: 4,
                    title: 'Verifiser epost',
                    routeName: this.routes.registration.verifyEmail
                },
                {
                    number: 5,
                    title: 'Registrer ansatte',
                    routeName: this.routes.registration.users
                }
            ];
            this.setCurrentStep(this.$route.name);
        },
        beforeRouteUpdate(to, from, next) {
            this.setCurrentStep(to.name);
            next();
        }
    };
</script>
