<template>
    <div>
        <div :class="sortable ? 'sortable' : null">
            <FormLabel
                :for-id="inputId"
                :label="label"
                :hide-label="hideLabel"
            />
            <div class="sortable__actions" v-if="sortable">
                <div>
                    <button
                        @click.prevent="$emit('on-move-up')"
                        class="btn btn--icon"
                        title="Flytt opp"
                    >
                        <span class="sr-only">Flytt opp</span>
                        <span
                            class="icon icon--chevron icon--chevron--up"
                        ></span>
                    </button>
                    <button
                        @click.prevent="$emit('on-move-down')"
                        class="btn btn--icon"
                        title="Flytt ned"
                    >
                        <span class="sr-only">Flytt ned</span>
                        <span
                            class="icon icon--chevron icon--chevron--down"
                        ></span>
                    </button>
                </div>
            </div>
        </div>

        <FormInput
            :id="inputId"
            :type="type"
            :name="inputName"
            :value="value"
            :inputClass="inputClass"
            :rows="rows"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :has-error="hasError"
            :aria-describedby="errorId"
            :autofocus="autofocus"
            :disabled="disabled"
            :maxlength="maxlength"
            @update:value="val => onInput(val)"
            @on-blur="onBlur"
            @on-change="onChange"
        />
        <span v-if="hasError" class="form-error" :id="errorId">
            {{ error }}
        </span>
    </div>
</template>

<script>
    export default {
        props: {
            label: String,
            value: String,
            name: String,
            type: {
                type: String,
                default: 'text'
            },
            rows: {
                type: Number,
                default: 7
            },
            hideLabel: {
                type: Boolean,
                default: false
            },
            placeholder: String,
            id: String,
            autocomplete: {
                type: String,
                default: 'off'
            },
            error: {
                type: String,
                default: null
            },
            autofocus: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            sortable: {
                type: Boolean,
                default: false
            },
            inputClass: String,
            maxlength: Number
        },
        data() {
            return {
                inputId: null,
                inputName: null
            };
        },
        computed: {
            errorId() {
                return this.hasError ? `error_${this.inputId}` : null;
            },
            hasError() {
                return !!(this.error && this.error.length);
            }
        },
        methods: {
            onInput(value) {
                this.$emit('update:value', value);
            },
            onBlur(event) {
                this.$emit('on-blur', event);
            },
            onChange(event) {
                this.$emit('on-change', event);
            }
        },
        mounted() {
            this.inputId = this.id ? this.id : 'input_' + this._uid;
            this.inputName = this.name
                ? this.name
                : this.label.toLowerCase().replace(/[\W_]+/g, '');
            this.inputValue = this.value;
        }
    };
</script>
