<template>
    <div class="page">
        <div class="section bg--light flex-grow">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
                        <form
                            action="/"
                            @submit.prevent="onSubmit"
                            v-if="!submitted"
                        >
                            <fieldset>
                                <legend>
                                    <h1 class="h2">{{ title }}</h1>
                                    <p class="sm">
                                        Fyll inn epostadressen din så sender vi
                                        en lenke for å sette et nytt passord.
                                    </p>
                                </legend>

                                <FormGroup
                                    v-bind="fields.username"
                                    :value.sync="fields.username.value"
                                    @on-blur="onFieldBlur"
                                    @on-change="onChange"
                                    :autofocus="true"
                                />
                            </fieldset>

                            <button
                                type="submit"
                                class="btn btn--primary btn--lg btn--block btn--form"
                            >
                                {{ btnTitle }}
                            </button>
                        </form>
                        <div v-if="submitted">
                            <h1 class="h2">Sjekk eposten din</h1>
                            <p>
                                Vi har sendt deg en lenke du kan bruke for å
                                tilbakestille passordet ditt.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import validationMixin from '../mixins/validationMixin';
    import validate from '../scripts/validate';
    import api from '../scripts/api';

    export default {
        mixins: [validationMixin],
        props: {
            title: {
                type: String,
                default: 'Glemt passord?'
            },
            btnTitle: {
                type: String,
                default: 'Send lenke på epost'
            },
            username: String
        },
        data() {
            return {
                submitted: false,
                fields: {
                    username: {
                        type: 'email',
                        name: 'username',
                        value: '',
                        hideLabel: true,
                        label: 'E-post *',
                        placeholder: 'E-post',
                        id: 'username',
                        autocomplete: 'username',
                        error: '',
                        maxlength: 254
                    }
                }
            };
        },
        methods: {
            onSubmit() {
                if (this.isFormValid()) {
                    api.glemtPassord(this.fields.username.value)
                        .then(() => {
                            this.submitted = true;
                        })
                        .catch(() => {});
                }
            },
            validateField(field) {
                let error = null;

                if (field.name === this.fields.username.name) {
                    if (!validate.emailIsValid(field.value)) {
                        error = 'Ugyldig e-post';
                    }
                    if (!validate.stringLength(field.value)) {
                        error = 'E-post må fylles inn';
                    }
                }

                field.error = error || '';
                field.validated = error ? true : false;

                return error;
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                if (vm.username) {
                    vm.fields.username.value = vm.username;
                    vm.isFormValid();
                }
            });
        }
    };
</script>
