import ErrorPage from '../views/Error.vue';
import Start from '../views/Start.vue';
import Introduction from '../views/Introduction.vue';

import Login from '../views/Login.vue';
import Logout from '../views/Logout.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import ResetPassword from '../views/ResetPassword.vue';

import Kalender from '../views/Kalender/Kalender.vue';
import KalenderMaaned from '../views/Kalender/KalenderMaaned.vue';
import KalenderHalvaar from '../views/Kalender/KalenderHalvaar.vue';

import EditAktivitet from '../views/Kalender/EditAktivitet.vue';

import Temaer from '../views/Tema/Temaer.vue';
import Tema from '../views/Tema/Tema.vue';
import Statistikk from '../views/Statistikk/Statistikk.vue';
import Registration from '../views/Registration/Organization/Registration.vue';
import RegisterBarnehage from '../views/Registration/Organization/Steps/RegisterBarnehage.vue';
import RegisterAdmin from '../views/Registration/Organization/Steps/RegisterAdmin.vue';
import RegistrationConfirm from '../views/Registration/Organization/Steps/RegistrationConfirm.vue';
import RegisterUsers from '../views/Registration/Organization/Steps/RegisterUsers.vue';
import RegistrationVerifyEmail from '../views/Registration/Organization/Steps/RegistrationVerifyEmail.vue';
import RegistrationSuccess from '../views/Registration/Organization/Steps/RegistrationSuccess.vue';
import RegisterUser from '../views/Registration/RegisterUser.vue';

import Admin from '../views/Admin/Admin';
import AdminUsers from '../views/Admin/AdminUsers';
import AdminAddUsers from '../views/Admin/AdminAddUsers';
import AdminUser from '../views/Admin/AdminUser';

import routeNames from './routeNames';
import { userRoles } from '../scripts/auth';

export default [
    {
        path: '*',
        name: 'notfounderror',
        redirect: { path: '/error/404' }
    },
    {
        name: 'servererror',
        path: '/error',
        redirect: { path: '/error/500' }
    },
    {
        component: ErrorPage,
        name: routeNames.error,
        path: '/error/:code',
        props: route => {
            return { code: parseInt(route.params.code) };
        }
    },
    {
        component: Start,
        name: routeNames.start,
        path: '/'
    },
    {
        component: Login,
        name: routeNames.login,
        path: '/logg-inn'
    },
    {
        component: Logout,
        name: routeNames.logout,
        path: '/logg-ut'
    },
    {
        component: ForgotPassword,
        name: routeNames.forgotPassword,
        path: '/glemt-passord',
        props: true
    },
    {
        component: ResetPassword,
        name: routeNames.resetPassword,
        path: '/tilbakestill-passord'
    },
    {
        component: Introduction,
        name: routeNames.introduction,
        path: '/om'
    },
    {
        component: Kalender,
        name: routeNames.kalender,
        path: '/kalender',
        meta: {
            requiresRole: userRoles.user
        },
        redirect: {
            name: routeNames.kalenderMonth,
            params: {
                year: new Date().getFullYear(),
                month: new Date().getMonth() + 1
            }
        },
        children: [
            {
                component: KalenderMaaned,
                name: routeNames.kalenderMonth,
                path: ':year/:month',
                props: route => {
                    const year = parseInt(route.params.year);
                    const month = parseInt(route.params.month);
                    return { year, month };
                },
                meta: {
                    requiresRole: userRoles.user
                }
            },
            {
                component: KalenderHalvaar,
                name: routeNames.kalenderHalvaar,
                path: ':year/halvar/:month',
                props: route => {
                    const year = parseInt(route.params.year);
                    const month = parseInt(route.params.month);
                    return { year, month };
                },
                meta: {
                    requiresRole: userRoles.user
                }
            }
        ]
    },
    {
        component: EditAktivitet,
        name: routeNames.createAktivitet,
        path: '/opprett-aktivitet',
        meta: {
            requiresRole: userRoles.orgAdmin
        }
    },
    {
        component: EditAktivitet,
        name: routeNames.editAktivitet,
        path: '/rediger-aktivitet/:id',
        props: true,
        meta: {
            requiresRole: userRoles.orgAdmin
        }
    },
    {
        component: Temaer,
        name: routeNames.temaer,
        path: '/tema'
    },
    {
        component: Statistikk,
        name: routeNames.statistikk,
        path: '/statistikk'
    },
    {
        component: Tema,
        name: routeNames.tema,
        path: '/tema/:id',
        props: true
    },

    {
        component: () =>
            import(
                /* webpackChunkName: "edit-temaer" */ '../views/Tema/EditTemaer.vue'
            ),
        name: routeNames.editTemaer,
        path: '/rediger/tema',
        meta: {
            requiresRole: userRoles.sysAdmin
        }
    },
    {
        component: () =>
            import(
                /* webpackChunkName: "edit-tema" */ '../views/Tema/EditTema.vue'
            ),
        name: routeNames.editTema,
        path: '/rediger/tema/:id',
        props: true,
        meta: {
            requiresRole: userRoles.sysAdmin
        }
    },
    {
        component: () =>
            import(
                /* webpackChunkName: "create-tema" */ '../views/Tema/EditTema.vue'
            ),
        name: routeNames.createTema,
        path: '/opprett-tema',
        meta: {
            requiresRole: userRoles.sysAdmin
        }
    },

    {
        component: Registration,
        name: routeNames.registration.organisasjon,
        path: '/registrering/barnehage',
        redirect: { name: routeNames.registration.barnehage },
        children: [
            {
                component: RegisterBarnehage,
                name: routeNames.registration.barnehage,
                path: 'barnehage-info'
            },
            {
                component: RegisterAdmin,
                name: routeNames.registration.adminBruker,
                path: 'opprett-admin'
            },
            {
                component: RegistrationConfirm,
                name: routeNames.registration.confirm,
                path: 'bekreft-valg',
                meta: {
                    disableCache: true
                }
            },
            {
                component: RegistrationVerifyEmail,
                name: routeNames.registration.verifyEmail,
                path: 'verifiser-epost',
                meta: {
                    disableCache: true
                }
            },
            {
                component: RegisterUsers,
                name: routeNames.registration.users,
                path: 'inviter-brukere',
                meta: {
                    requiresRole: userRoles.orgAdmin
                }
            },
            {
                component: RegistrationSuccess,
                name: routeNames.registration.success,
                path: 'registrering-fullfoert',
                meta: {
                    requiresRole: userRoles.orgAdmin
                }
            }
        ]
    },
    {
        component: RegisterUser,
        name: routeNames.registration.user,
        path: '/registrering/bruker'
    },
    {
        component: Admin,
        name: 'Admin',
        path: '/admin',
        redirect: {
            name: routeNames.admin.users
        },
        children: [
            {
                component: AdminUsers,
                name: routeNames.admin.users,
                path: 'brukere',
                meta: {
                    requiresRole: userRoles.orgAdmin
                }
            },
            {
                component: AdminAddUsers,
                name: routeNames.admin.addUsers,
                path: 'legg-til-brukere',
                meta: {
                    requiresRole: userRoles.orgAdmin
                }
            },
            {
                component: AdminUser,
                name: routeNames.admin.user,
                path: 'rediger-bruker',
                props: true,
                meta: {
                    requiresRole: userRoles.orgAdmin
                }
            }
        ]
    }
];
