import Vue from 'vue';
import Vuex from 'vuex';

import { guid } from '../scripts/utils';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loggedInStatus: null,
        cacheKey: null,
        regProps: {},
        temaCache: {}
    },
    mutations: {
        SET_LOGGED_IN_STATUS(state, loggedInStatus) {
            state.loggedInStatus = loggedInStatus;
        },
        SET_CACHE_KEY(state, cacheKey) {
            state.cacheKey = cacheKey;
        },
        SET_REG_PROP(state, { key, value }) {
            state.regProps[key] = value;
        },
        SET_TEMA_CACHE(state, temaer) {
            state.temaCache = {};
            temaer.map(tema => {
                state.temaCache[tema.id] = tema;
            });
        }
    },
    actions: {
        setLoggedInStatus({ commit }, loggedInStatus) {
            commit('SET_LOGGED_IN_STATUS', loggedInStatus);
        },
        updateCacheKey({ commit }) {
            commit('SET_CACHE_KEY', guid());
        },
        setRegProps({ commit }, props) {
            for (const key in props) {
                commit('SET_REG_PROP', { key, value: props[key] });
            }
        },
        setTemaCache({ commit }, temaer = []) {
            commit('SET_TEMA_CACHE', temaer);
        }
    },
    modules: {}
});
