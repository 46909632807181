<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
                <div class="section section--form">
                    <h2>Bekreft informasjon</h2>
                    <p>
                        Verifiser at informasjonen du har registrert stemmer.
                    </p>
                    <p>
                        Du kan ikke endre organisasjonsnummer senere.
                    </p>
                    <p>
                        Bruk tilbakeknappen hvis du må endre noe.
                    </p>

                    <ul class="list--reg-fields">
                        <li>
                            <strong>Barnehage:</strong>
                            {{ props[regPropNames.barnehagenavn] }}
                        </li>
                        <li>
                            <strong>Organisasjonsnummer:</strong>
                            {{ props[regPropNames.organisasjonsnummer] }}
                        </li>
                        <li>
                            <strong>Admin bruker:</strong>
                            {{ props[regPropNames.name] }}
                        </li>
                        <li>
                            <strong>E-post:</strong>
                            {{ props[regPropNames.email] }}
                        </li>
                    </ul>
                </div>
                <div class="d-flex align-items-center">
                    <div class="flex-grow">
                        <router-link
                            :to="{ name: routes.registration.adminBruker }"
                        >
                            Tilbake
                        </router-link>
                    </div>
                    <div>
                        <button
                            class="btn btn--primary btn--lg"
                            @click.prevent="onNextClick"
                        >
                            Bekreft
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import api from '../../../../scripts/api';
    import regProps from '../../../../constants/regProps';

    export default {
        computed: {
            ...mapState(['regProps'])
        },
        data() {
            return {
                regPropNames: regProps,
                props: null
            };
        },
        methods: {
            onNextClick() {
                api.registerOrganisasjon(this.props)
                    .then(() => {
                        this.$router.push({
                            name: this.routes.registration.verifyEmail
                        });
                    })
                    .catch(err => {
                        const errors = err.response.data.errors;

                        this.$router.push({
                            name: this.routes.registration.adminBruker,
                            params: {
                                errors: errors
                            }
                        });
                    });
            }
        },
        beforeMount() {
            this.props = this.regProps;
        },
        beforeRouteUpdate(to, from, next) {
            this.props = this.regProps;
            next();
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                if (
                    !vm.regProps[regProps.barnehagenavn] ||
                    !vm.regProps[regProps.organisasjonsnummer]
                ) {
                    return next({ name: vm.routes.registration.barnehage });
                }

                if (
                    !vm.regProps[regProps.name] ||
                    !vm.regProps[regProps.email] ||
                    !vm.regProps[regProps.password]
                ) {
                    return next({ name: vm.routes.registration.adminBruker });
                }
            });
        }
    };
</script>
