var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"header"},[_c('div',{staticClass:"container"},[_c('nav',{staticClass:"nav",attrs:{"id":"nav"}},[_c('div',{staticClass:"flex-grow-1"},[_c('div',{staticClass:"logo nav-item"},[_c('router-link',{staticClass:"router-link-active--no-style",attrs:{"to":{ name: _vm.routes.start }}},[_vm._v(" BarnehageHMS ")])],1)]),_c('ul',{staticClass:"nav-list"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":{ name: _vm.routes.temaer }}},[_vm._v(" HMS-tema ")])],1),(_vm.isLoggedIn() && _vm.isSysAdmin())?_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":{ name: _vm.routes.statistikk }}},[_vm._v(" Statistikk ")])],1):_vm._e(),(_vm.isLoggedIn() && !_vm.isSysAdmin())?_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":{ name: _vm.routes.kalender }}},[_vm._v(" Kalender ")])],1):_vm._e(),(_vm.isSysAdmin())?_c('li',{staticClass:"nav-item"},[_c('router-link',{attrs:{"to":{ name: _vm.routes.editTemaer }}},[_vm._v(" Rediger HMS-tema ")])],1):_vm._e(),(
                        _vm.isLoginChecked() &&
                            !_vm.isLoggedIn() &&
                            _vm.$route.name !== _vm.routes.login &&
                            _vm.$route.name !== _vm.routes.registration.verifyEmail
                    )?_c('li',{},[_c('router-link',{staticClass:"btn btn--primary btn--login",attrs:{"to":{ name: _vm.routes.login }}},[_vm._v(" Logg inn ")])],1):_vm._e(),(_vm.isLoggedIn())?_c('li',{staticClass:"nav-dropdown"},[_c('Dropdown',{attrs:{"toggle-class":'btn--icon btn--icon--round'},scopedSlots:_vm._u([{key:"toggleContent",fn:function(){return [_c('span',{staticClass:"icon icon--user"}),_c('span',{staticClass:"sr-only"},[_vm._v(" "+_vm._s(_vm.loggedInStatus.rollenavn)+" ")])]},proxy:true},{key:"dropdownContent",fn:function(){return [_c('li',{staticClass:"dropdown-name"},[_vm._v(" "+_vm._s(_vm.loggedInStatus.navn)+" ")]),_c('li',[_vm._v(" "+_vm._s(_vm.loggedInStatus.organisasjonsnavn)+" ")]),(_vm.isOrgAdmin() && !_vm.isSysAdmin())?_c('li',[_c('router-link',{staticClass:"no-wrap",attrs:{"to":{
                                        name: _vm.routes.admin.users
                                    }}},[_vm._v(" Administrer brukere ")])],1):_vm._e(),_c('li',[_c('router-link',{staticClass:"btn btn--primary",attrs:{"to":{
                                        name: _vm.routes.logout
                                    }}},[_vm._v(" Logg ut ")])],1)]},proxy:true}],null,false,3726988702)})],1):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }