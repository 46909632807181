<template>
    <div class="page">
        <div class="section bg--light flex-grow">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
                        <form action="/" @submit.prevent="onSubmit">
                            <fieldset>
                                <legend>
                                    <h2>{{ title }}</h2>
                                </legend>

                                <FormGroup
                                    v-bind="fields.newPassword"
                                    :value.sync="fields.newPassword.value"
                                    @on-blur="onFieldBlur"
                                    @on-change="onChange"
                                />
                                <FormGroup
                                    v-bind="fields.re_password"
                                    :value.sync="fields.re_password.value"
                                    @on-blur="onFieldBlur"
                                    @on-change="onChange"
                                />
                            </fieldset>
                            <div v-if="error">
                                <p class="form-error">
                                    Kunne ikke sette nytt passord. Er det lenge
                                    siden du ba om å tilbakestille passordet kan
                                    du starte prosessen på nytt.
                                </p>
                                <div class="text-center mb-40">
                                    <router-link
                                        :to="{
                                            name: routes.forgotPassword,
                                            params: {
                                                username: user
                                            }
                                        }"
                                    >
                                        Glemt passord?
                                    </router-link>
                                </div>
                            </div>
                            <button
                                v-if="!error"
                                type="submit"
                                class="btn btn--primary btn--lg btn--block btn--form"
                            >
                                {{ btnTitle }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import validationMixin from '../mixins/validationMixin';
    import validate from '../scripts/validate';
    import errorCodes from '../constants/errorCodes';
    import api from '../scripts/api';
    import { mapErrorsToInputs } from '../scripts/utils';

    export default {
        mixins: [validationMixin],
        props: {
            title: {
                type: String,
                default: 'Tilbakestill passord'
            },
            btnTitle: {
                type: String,
                default: 'Sett nytt passord'
            }
        },
        data() {
            return {
                user: null,
                token: null,
                error: false,
                fields: {
                    newPassword: {
                        type: 'password',
                        name: 'newPassword',
                        value: '',
                        label: 'Nytt passord *',
                        error: '',
                        maxlength: 255,
                        id: 'new-password',
                        autocomplete: 'new-password'
                    },
                    re_password: {
                        type: 'password',
                        name: 're_password',
                        value: '',
                        label: 'Bekreft passord *',
                        error: '',
                        autocomplete: 'new-password'
                    }
                }
            };
        },
        methods: {
            onSubmit() {
                const self = this;
                if (this.isFormValid()) {
                    api.tilbakestillPassord({
                        token: this.token,
                        epost: this.user,
                        newPassword: this.fields.newPassword.value
                    })
                        .then(() => {
                            this.$router.push({
                                name: this.routes.login,
                                query: {
                                    user: this.user
                                }
                            });
                        })
                        .catch(err => {
                            const errors = err.response.data.errors;

                            if (errors && Object.keys(errors).length) {
                                mapErrorsToInputs(self.fields, errors);
                                return;
                            }
                            self.error = true;
                        });
                }
            },
            validateField(field) {
                let error = null;

                error = validate.validatePasswords(
                    field,
                    this.fields.newPassword,
                    this.fields.re_password
                );

                field.error = error || '';
                field.validated = error ? true : false;

                return error;
            }
        },
        beforeRouteEnter(to, from, next) {
            next(vm => {
                if (!to.query.user || !to.query.token) {
                    next({
                        name: vm.routes.error,
                        params: {
                            code: errorCodes.notfoundError
                        }
                    });
                }
                vm.user = to.query.user;
                vm.token = to.query.token;
            });
        }
    };
</script>
