<template>
    <div class="container">
        <div class="row">
            <div class="col-md-6 offset-md-3 col-xxl-4 offset-xxl-4">
                <form action="/" @submit.prevent="onSubmit">
                    <div class="section section--form">
                        <fieldset>
                            <legend>
                                <h2>Registrer barnehage</h2>
                                <p class="sm">
                                    Registrer barnehage dersom du er leder i en
                                    barnehage.
                                </p>
                            </legend>
                            <FormGroup
                                v-bind="fields.barnehagenavn"
                                :value.sync="fields.barnehagenavn.value"
                                @on-blur="onFieldBlur"
                                @on-change="onChange"
                            />

                            <FormGroup
                                v-bind="fields.organisasjonsnummer"
                                :value.sync="fields.organisasjonsnummer.value"
                                @on-blur="onFieldBlur"
                                @on-change="onChange"
                            />
                        </fieldset>
                    </div>
                    <button
                        class="btn btn--primary btn--lg btn--block"
                        @click.prevent="onNextClick"
                    >
                        Neste
                    </button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import validationMixin from '../../../../mixins/validationMixin';
    import {
        inputsToObject,
        mapErrorsToInputs
    } from '../../../../scripts/utils';
    import api from '../../../../scripts/api';
    import validate from '../../../../scripts/validate';
    import regProps from '../../../../constants/regProps';

    export default {
        mixins: [validationMixin],
        data() {
            return {
                fields: {
                    barnehagenavn: {
                        type: 'text',
                        name: regProps.barnehagenavn,
                        value: '',
                        label: 'Navn på barnehagen *',
                        error: '',
                        maxlength: 200,
                        autocomplete: 'organization',
                        id: 'organization'
                    },
                    organisasjonsnummer: {
                        type: 'text',
                        name: regProps.organisasjonsnummer,
                        value: '',
                        maxlength: 9,
                        label: 'Organisasjonsnummer *',
                        error: ''
                    }
                }
            };
        },
        methods: {
            ...mapActions(['setRegProps']),
            onNextClick() {
                if (this.isFormValid()) {
                    const self = this;
                    let regProps = inputsToObject(this.fields);

                    api.validateOrganisasjon(regProps)
                        .then(() => {
                            this.setRegProps(regProps).then(() => {
                                this.$router.push({
                                    name: this.routes.registration.adminBruker
                                });
                            });
                        })
                        .catch(err => {
                            const errors = err.response.data.errors;

                            if (errors) {
                                mapErrorsToInputs(self.fields, errors);
                            }
                        });
                }
            },

            validateField(field) {
                let error = null;

                if (field.name === this.fields.barnehagenavn.name) {
                    if (!validate.stringLength(field.value)) {
                        error = 'Navn på barnehagen må fylles inn';
                    }
                }

                if (field.name === this.fields.organisasjonsnummer.name) {
                    if (!validate.stringLength(field.value)) {
                        error = 'Organisasjonsnummer må fylles inn';
                    }
                }

                field.error = error || '';
                field.validated = error ? true : false;

                return error;
            }
        }
    };
</script>
