import './scripts/initialize';

import VueGtm from '@gtm-support/vue2-gtm'
import Vue from 'vue';
import App from './App.vue';

import router from './router';
import store from './store';

import RouteMixin from './mixins/routeMixin';
import AuthMixin from './mixins/authMixin';

import FormLabel from './components/Form/FormLabel';
import FormInput from './components/Form/FormInput';
import FormGroup from './components/Form/FormGroup';

Vue.config.productionTip = false;

Vue.mixin(RouteMixin);
Vue.mixin(AuthMixin);

Vue.component('FormLabel', FormLabel);
Vue.component('FormInput', FormInput);
Vue.component('FormGroup', FormGroup);

Vue.use(VueGtm, {
    id: 'GTM-TZ972VW',
    defer: false,
    enabled: true,
    debug: false,
    loadScript: true,
    vueRouter: router,
    trackOnNextTick: false,
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
